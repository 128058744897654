import { Link } from "react-router-dom";

export default function TransmissionHero() {
    return (
        <div className="flex items-start space-x-[25px] p-4">
            {/* Left Column - Hero Image */}
            <img src="./images/wireframe_transmission.png" alt="Transmission Radio" className="w-[200px] h-[410px] md:w-[270px] md:h-[547px]" />

            {/* Right Column */}
            <div className="flex flex-col space-y-4 w-[270px] mt-[10px]">
                <img src="./images/logo_transmission.png" alt="Transmission Radio" className="w-[70px] h-[70px] md:w-[100px] md:h-[100px]" />
                <h2 className="text-lg md:text-xl font-bold">Transmission Radio</h2>
                <p className="text-gray-700 text-sm md:text-base">
                    Listen to thousands of free radio stations from around the world. Choose among numerous music genres or enjoy
                    listening to news and talk radio, all in a simple and elegant radio player.
                </p>

                <Link to="https://apps.apple.com/app/apple-store/id1633197545?pt=2322962&ct=Verbia%20Web&mt=8">
                    <img src="./images/logo_appstore.svg" alt="Download on the App Store" className="w-[92px] h-[30px] md:w-[123px] md:h-[40px]" />
                </Link>
            </div>
        </div>
    )
}