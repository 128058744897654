import { Link, useLocation } from 'react-router-dom';

export default function Header() {
    const location = useLocation();

    const getLinkClass = (path) =>
        location.pathname === path
            ? "px-6 py-1 bg-black text-white rounded-full text-xs md:text-sm"
            : "px-6 py-1 bg-gray-200 hover:bg-gray-300 rounded-full text-xs md:text-sm";

    return (
        <div className="w-full flex flex-col sm:flex-row sm:items-center sm:justify-between p-4">
            {/* Left column */}
            <Link to="/" className="sm:w-auto w-full text-left sm:text-left">
                <img src="/images/logo_verbia.svg" alt="VERBIA" className="w-[213px] h-[47px] md:w-[285px] md:h-[63px] mb-4" />
            </Link>

            {/* Right column */}
            <div className="flex sm:flex-row flex-col sm:space-x-4 space-y-2 sm:space-y-0 sm:justify-start sm:ml-0 ml-auto">
                <Link to="/privacy" className={getLinkClass("/privacy")}>
                    Privacy Policy
                </Link>
                <Link to="/terms" className={getLinkClass("/terms")}>
                    Terms of Use
                </Link>
            </div>
        </div>
    )
}