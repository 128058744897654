import TransmissionHero from "./transmission";
import PdfLibraryHero from "./pdflibrary";
import Header from "./Header";

export default function LandingMain() {
    return (
        <>
            <Header />

            <div className="pl-[35px] py-[30px] md:py-[55px]" style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}>
                <p className="text-gray-500 text-3xl md:text-4xl">
                    Inclusive and <br />
                    empowering apps
                </p>
            </div>

            <div className="flex flex-col lg:flex-row lg:space-x-10">
                <PdfLibraryHero />
                <TransmissionHero />
            </div>
        </>
    );
}