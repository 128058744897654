import { Link } from 'react-router-dom';

export default function PdfLibraryHero() {
    return (
        <div className="flex items-start space-x-[25px] p-4">
            {/* Left Column - Hero Image */}
            <img src="./images/wireframe_pdflibrary.png" alt="PDF Library" className="w-[200px] h-[410px] md:w-[270px] md:h-[547px]" />

            {/* Right Column */}
            <div className="flex flex-col space-y-4 w-[270px] mt-[10px]">
                <img src="./images/logo_pdflibrary.png" alt="PDF Library" className="w-[70px] h-[70px] md:w-[100px] md:h-[100px]" />
                <h2 className="text-lg md:text-xl font-bold">PDF Library</h2>
                <p className="text-gray-700 text-sm md:text-base">
                    The ultimate solution for reading long-form PDF documents and eBooks. Seamlessly manage, organise, and sync your 
                    collection across all your devices.
                </p>
                <Link to="https://apps.apple.com/app/apple-store/id6503659712?pt=2322962&ct=Verbia%20web&mt=8">
                    <img src="./images/logo_appstore.svg" alt="Download on the App Store" className="w-[92px] h-[30px] md:w-[123px] md:h-[40px]" />
                </Link>
            </div>
        </div>
    )
}