import Header from "./Header"

export default function PrivacyPage() {
    return (
        <>
            <Header />

            <div className="relative overflow-hidden bg-white py-8">
                <div className=" max-w-7xl px-2 sm:px-6 lg:px-8 relative px-4 sm:px-6 lg:px-8">
                    <div className="max-w-prose text-lg">
                        <h1>
                            <span className="mt-2 block text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                                Privacy Policy
                            </span>
                        </h1>
                        <p className="mt-8 text-xl leading-8 text-gray-700">
                            This Privacy Policy describes how our apps collect, use, and disclose information — and what
                            choices you have with respect to the information.
                        </p>
                    </div>
                    <div className="prose prose-lg prose-indigo mt-6 text-gray-700">
                        <h3 className="py-1"><b>What Information We Collect and Process</b></h3>

                        <p className="py-1">Privacy by design. Transmission is designed and developed to be simple and approachable radio that
                            users enjoy using. This means that we are not in the business of gathering and gathering users' personal
                            information and their interests and selling it to advertisers or using it internally.</p>

                        <p className="py-1">The sections below are aimed at providing more information about how Transmission processes different types
                            of data.</p>

                        <p className="py-1">Favourite stations. Transmission stores your bookmarks of your favourite stations locally on your device. If
                            iCloud is enabled, then these favourites are stored in your private iCloud account by Apple. Transmission
                            will never collect or process which radio stations you may have saved or how often to listen to them. This
                            data is entirely private to you.</p>

                        <p className="py-1">Location information. Transmission neither requests permission to access your device's location from iOS,
                            nor does it attempt to calculate your device's exact or approximate location. Transmission does however use
                            your device's region settings ("locale" and "language") in order to show stations in the Featured section that
                            might be relevant to you based on your device's country and language setting. Our servers do not store or process
                            this information other than to deliver search results.</p>

                        <p className="py-1">Log data. Transmission may generate log information internally as it is being used, which is recorded in
                            log files on your device. Such log data may include the IP address, the section of the app being used, the device
                            details and settings, the date and time the services were used, crash data, and language preferences. This data
                            remains on your device and cannot be accessed unless you choose to send it, for example when requesting
                            support from the "Contact us" screen. When dooing so, the fields and data that are being sent with your
                            support request appear on the screen for you to review.</p>

                        <p className="py-1">Information you choose to provide. If you contact us, for example by using the "Contact Us" screen in the app or
                            via our web site, then we would need to use the information you provide such as your name and email address in
                            order to respond to your feedback, comments and questions.</p>

                        <h3 className="py-1"><b>Data Retention</b></h3>

                        <p className="py-1">Transmission does not collect personally identifying information and therefore does not retain data about users
                            which may require retention or deletion.</p>

                        <h3 className="py-1"><b>Requesting Data Deletion</b></h3>

                        <p className="py-1">The exception to the above is if you have contacted us previously via Support, in which case you have the right to
                            request at any time that we delete from our systems any record of you having contact us. Due to the nature of support
                            requests this will be a manual process. Send an email to (privacy at verbia dot com) to
                            request that we delete your information.</p>

                        <h3 className="py-1"><b>How We Share And Disclose Information</b></h3>

                        <p className="py-1">We do not share or disclose information since it is our policy and principle to not gather personal information
                            at all.</p>

                        <h3 className="py-1"><b>Changes To This Privacy Policy</b></h3>

                        <p className="py-1">This Privacy Policy may change from time to time if necessitated by changing laws, regulations, or industry standards;
                            or we may make changes to our business. We will post the changes to this page. If we make changes that materially alter
                            your privacy rights, Transmission will provide clear, unambiguous notice through the app interface. If you disagree with
                            the changes to this privacy policy, you should uninstall the app and cease to use it.</p>

                        <h3 className="py-1"><b>International Data Transfers</b></h3>

                        <p className="py-1">When contacting Transmission for support, your personal data (name and email address) may be transferred
                            to a country other than the one in which you live.</p>

                        <h3 className="py-1"><b>Data Protection Officer</b></h3>

                        <p className="py-1">To communicate with our Data Protection Officer, please email (privacy at verbia dot com).</p>

                        <h3 className="py-1"><b>Your Rights</b></h3>

                        <p className="py-1">Individuals located in certain countries, including the European Economic Area, have certain statutory rights in
                            relation to their personal data. Subject to any exemptions provided by law, you may have the right to request access
                            to information, as well as to seek to update, delete or correct this information. You can usually do this using the
                            settings and tools provided in your services account. If you cannot use the settings and tools, contact customer support
                            for additional access and assistance.</p>

                        <p className="py-1">To the extent that Transmission's processing of your personal data is subject to the General Data Protection
                            Regulation (GDPR), Transmission relies on its legitimate interests, described above, to process your data.</p>
                    </div>
                </div>
            </div>
        </>
    )
}